<template>
  <div>
    <v-toolbar flat class="mb-4">
      <SchoolClassInput
        v-model="schoolClass"
        hide-details
        label="Schulklasse"
        class="mr-4"
        clearable
      />
      <LookupValueInput
        v-model="subjectNow"
        :items="subjects"
        hide-details
        label="Fach besucht"
        class="mr-4"
        clearable
      />
      <LookupValueInput
        v-model="subjectNext"
        :items="subjects"
        hide-details
        label="Fach gewählt"
        class="mr-4"
        clearable
      />

      <v-switch v-model="notConfirmed" label="muss noch bestätigen" />
    </v-toolbar>

    <v-card>
      <v-data-table
        :items="itemsFiltered"
        :headers="headers"
        :loading="loading"
        :items-per-page="50"
        sort-by="student"
      >
        <template v-slot:item.student="{ item }">
          <PersonItem small :value="item.student" />
        </template>

        <template v-slot:item.action="{ item }">
          <v-btn
            icon
            :to="{
              name: 'OptionalChoiceDetails',
              params: { schoolClass: schoolClass, student: item.student.id },
            }"
            ><v-icon>mdi-pen</v-icon></v-btn
          >
        </template>
        <template v-slot:item.status="{ item }">
          <v-simple-checkbox
            v-if="item.needsToConfirm"
            :value="item.hasConfirmed"
          />
        </template>
        <template v-slot:item.currentTerm="{ item }">
          <v-tooltip
            top
            v-for="subject in item.chosenNow"
            :key="`${item.student.id}now${subject.id}`"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-chip v-bind="attrs" v-on="on" color="info" small>{{
                subject.code
              }}</v-chip>
            </template>
            <span>{{ subject.description }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.nextTerm="{ item }">
          <v-tooltip
            top
            v-for="subject in item.chosenNext"
            :key="`${item.student.id}next${subject.id}`"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-chip v-bind="attrs" v-on="on" color="success" small>{{
                subject.code
              }}</v-chip>
            </template>
            <span>{{ subject.description }}</span>
          </v-tooltip>
        </template>
        <template v-slot:header.currentTerm="{}">
          {{ data.currentTerm ? data.currentTerm.fullText : "" }}
        </template>
        <template v-slot:header.nextTerm="{}">
          {{ data.nextTerm ? data.nextTerm.fullText : "" }}
        </template>
      </v-data-table>
      <router-view></router-view>
    </v-card>
    <router-view></router-view>
  </div>
</template>

<script>
import { comparePeople, searchPerson } from "common/utils/people.js";
import PersonItem from "@/components/PersonItem.vue";
import LookupValueInput from "common/components/LookupValueInput.vue";
import SchoolClassInput from "@/components/SchoolClassInput.vue";
export default {
  components: { LookupValueInput, PersonItem, SchoolClassInput },
  props: ["search"],
  data() {
    return {
      schoolClass: null,
      subjectNext: null,
      subjectNow: null,
      subjects: [],
      loading: false,
      notConfirmed: false,
      data: {},
      headers: [
        {
          text: "",
          value: "action",
        },
        {
          text: "Schüler*in",
          value: "student",
          sort: comparePeople,
        },
        {
          text: "bestätigt",
          value: "status",
        },
        {
          text: "currentTerm",
          value: "currentTerm",
        },
        {
          text: "nextTerm",
          value: "nextTerm",
        },
      ],
    };
  },
  watch: {
    student(value) {
      if (value) {
        this.$router.push({
          name: "OptionalChoiceDetails",
          params: { student: value.id, schoolClass: value.schoolClass.id },
        });
      }
    },
  },
  computed: {
    itemsFiltered() {
      if (!this.data || !this.data.studentItems) {
        return [];
      }

      return this.data.studentItems.filter(
        (el) =>
          (this.schoolClass == null ||
            this.schoolClass.code == el.student?.schoolClass?.code) &&
          (this.subjectNow == null ||
            el.chosenNow.some((item) => item.code == this.subjectNow.code)) &&
          (this.subjectNext == null ||
            el.chosenNext.some((item) => item.code == this.subjectNext.code)) &&
          (!this.notConfirmed || (el.needsToConfirm && !el.hasConfirmed)) &&
          (this.search == null ||
            this.search == "" ||
            el.student?.schoolClass?.code
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            searchPerson(el.student, this.search))
      );
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.subjects = await this.apiList({
        resource: "optional/subject",
      });

      this.data = await this.apiList({
        resource: "optional/choice",
      });
      this.loading = false;
    },
  },

  async created() {
    this.fetchData();
  },
  async beforeRouteUpdate(to, from, next) {
    if (from.name === "OptionalChoiceDetails") {
      await this.fetchData();
    }
    next();
  },
};
</script>
